import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import "react-modern-drawer/dist/index.css";
import { colors } from "../../../utils/colors";
import { X } from "react-feather";
import { Link } from "react-router-dom";
import Button from "../../elements/Button";
import ICS from "../../../assets/images/ICS.webp";
import FacebookLogo from "../../../assets/images/FacebookLogo.svg";
import InstagramLogo from "../../../assets/images/InstagramLogo.svg";
import LinkedinLogo from "../../../assets/images/LinkedinLogo.svg";
import YoutubeLogo from "../../../assets/images/YoutubeLogo.svg";
import TwitterLogo from "../../../assets/images/TwitterLogo.svg";
import PintrestLogo from "../../../assets/images/PintrestLogo.svg";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from 'react-redux';
import FacebookGradient from "../../../assets/images/facebook-gradient.svg"
import InstagramGradient from "../../../assets/images/instagram-gradient.svg"
import LinkdinGradient from "../../../assets/images/linkdin-gradient.svg"
import TwitterGradient from "../../../assets/images/twitter-gradient.svg"
import YoutubeGradient from "../../../assets/images/youtube-gradient.svg"
import PrintrestGradient from "../../../assets/images/printerest-gradient.svg"
import { useNavigate } from "react-router-dom";
import "./index.css";

const DrawerNav = (props) => {
  const { isOpen, setIsOpen } = props;
  const region = useSelector((state) => state.region.region);
  const navigate = useNavigate()
  const toggleDrawer = () => { };

  const content = [
    {
      title: "Home",
      link: "/us-en",
    },
    {
      title: "Portfolio",
      link: "/us-en/portfolio"
    },
    {
      title: "Who We Are",
      link: "/us-en/about-us",
    },
    {
      title: "Expertise",
      link: "/us-en/expertise",
    },
    {
      title: "Blogs",
      link: "/us-en/creative-blogs",
    },
    {
      title: "Contact Us",
      link: "/us-en/contact-us",
    },
  ];
  const contentAe = [
    {
      title: "Home",
      link: "/ae-en",
    },
    {
      title: "Portfolio",
      link: "/ae-en/portfolio"
    },
    {
      title: "Who We Are",
      link: "/ae-en/about-us",
    },
    {
      title: "Expertise",
      link: "/ae-en/expertise",
    },
    {
      title: "Blogs",
      link: "/ae-en/creative-blogs",
    },
    {
      title: "Contact Us",
      link: "/ae-en/contact-us",
    },
  ];
  const [facebookLogoState, setFacebookLogoState] = useState()
  const [instagramLogoState, setInstagramLogoState] = useState()
  const [linkdinLogoState, setLinkdinLogoState] = useState()
  const [twitterLogoState, setTwitterLogoState] = useState()
  const [youtubeLogoState, setYoutubeLogoState] = useState()
  const [pintrestLogoState, setPintrestLogoState] = useState()
  useEffect(() => {
    setFacebookLogoState(FacebookLogo)
    setInstagramLogoState(InstagramLogo)
    setLinkdinLogoState(LinkedinLogo)
    setTwitterLogoState(TwitterLogo)
    setYoutubeLogoState(YoutubeLogo)
    setPintrestLogoState(PintrestLogo)
  }, [FacebookLogo, InstagramLogo, LinkedinLogo, TwitterLogo, YoutubeLogo, PintrestLogo])

  const changeHoverState = (value) => {
    if (value === 'facebook gradient') {
      setFacebookLogoState(FacebookGradient)
    }
    else if (value === 'facebook simple') {
      setFacebookLogoState(FacebookLogo)
    }

    else if (value === 'instagram gradient') {
      setInstagramLogoState(InstagramGradient)
    }
    else if (value === 'instagram simple') {
      setInstagramLogoState(InstagramLogo)
    }
    else if (value === 'linkdin simple') {
      setLinkdinLogoState(LinkedinLogo)
    }
    else if (value === 'linkdin gradient') {
      setLinkdinLogoState(LinkdinGradient)
    }
    else if (value === 'twitter simple') {
      setTwitterLogoState(TwitterLogo)
    }
    else if (value === 'twitter gradient') {
      setTwitterLogoState(TwitterGradient)
    }
    else if (value === 'youtube simple') {
      setYoutubeLogoState(YoutubeLogo)
    }
    else if (value === 'youtube gradient') {
      setYoutubeLogoState(YoutubeGradient)
    }
    else if (value === 'pintrest simple') {
      setPintrestLogoState(PintrestLogo)
    }
    else if (value === 'pintrest gradient') {
      setPintrestLogoState(PrintrestGradient)
    }
  }
  return (
    <Container fluid className="drawer-nav m-0" style={{ backgroundColor: colors.white, overflow: 'hidden' }}>
      <Row className="vh-100" style={{ overflow: 'hidden' }}>
        
        <Col
          lg={6} md={12}
          className=" main-container d-flex align-items-center justify-content-center px-0"
        >
        
          <img
            onClick={() => {
              window.open("/");
            }}
            src={ICS}
            alt="image-here"
            width="100px"
            height="50px"
            style={{ cursor: "pointer" }}
          />
          <div className="d-lg-none d-md-none d-block">
            <X
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
                size={40}
                color="white"
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  top: "5.5%",
                  right: "5.7%",
                }}
              />
              </div>
        </Col>
        <Col
          className="animated-column d-flex flex-column justify-content-center"
          lg={6} md={12}
        >
          <Row>
            <Col md={12} xs={12} className="text-end d-lg-block d-md-block d-none">
              <X
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
                size={40}
                color="white"
                style={{
                  cursor: "pointer",
                  position: "fixed",
                  top: "5.5%",
                  right: "5.7%",
                }}
              />
            </Col>
            {region === "ae" ? (
              contentAe?.map((items, index) => (
                <Col md={12} className="d-flex justify-content-center" key={index}>
                  <div style={{ width: "50%" }} className="d-flex">
                    <Link
                      to={items.link}
                      className="text-decoration-none d-flex  justify-content-center py-2"
                    >
                      <h2 className="text-gradient-hover mb-0">{items.title}</h2>
                    </Link>
                  </div>
                </Col>
              ))
            ) : (
              content?.map((items, index) => (
                <Col md={12} className="d-flex justify-content-center" key={index}>
                  <div style={{ width: "50%" }} className="d-flex">
                    <Link
                      to={items.link}
                      className="text-decoration-none d-flex  justify-content-center py-2"
                    >
                      <h2 className="text-gradient-hover mb-0">{items.title}</h2>
                    </Link>
                  </div>
                </Col>
              ))
            )}
          </Row>
          <Row className="pt-3">
            <Col lg={3} md={3} xs={2}></Col>
            <Col lg={7} md={6} xs={8} className="d-flex justify-content-between">
              <span onClick={() => navigate("//www.facebook.com/ics.studiomedia")} onMouseOver={() => changeHoverState('facebook gradient')} onMouseLeave={() => changeHoverState('facebook simple')} style={{ marginRight: '5px' }}>
                <Button lefticon={<img src={facebookLogoState} width='23px' alt="image-here" />} backgroundColor={colors.transparent} borderColor={"gradient"} />
              </span>

              <span onClick={() => navigate("//www.instagram.com/ics.studiomedia/")} onMouseOver={() => changeHoverState('instagram gradient')} onMouseLeave={() => changeHoverState('instagram simple')} style={{ marginRight: '5px' }}>
                <Button lefticon={<img src={instagramLogoState} width='23px' alt="image-here" />} backgroundColor={colors.transparent} borderColor={"gradient"} />
              </span>

              <span onClick={() => navigate("//www.linkedin.com/company/icreativesol/")} onMouseOver={() => changeHoverState('linkdin gradient')} onMouseLeave={() => changeHoverState('linkdin simple')} style={{ marginRight: '5px' }}>
                <Button lefticon={<img src={linkdinLogoState} width='23px' alt="image-here" />} backgroundColor={colors.transparent} borderColor={"gradient"} />
              </span>

              <span onClick={() => navigate("//youtube.com/@icreativesol5570")} onMouseOver={() => changeHoverState('youtube gradient')} onMouseLeave={() => changeHoverState('youtube simple')} style={{ marginRight: '5px' }}>
                <Button lefticon={<img src={youtubeLogoState} width='23px' alt="image-here" />} backgroundColor={colors.transparent} borderColor={"gradient"} />

              </span>
              <span onClick={() => navigate("//x.com/ics_studiomedia")} onMouseOver={() => changeHoverState('twitter gradient')} onMouseLeave={() => changeHoverState('twitter simple')} style={{ marginRight: '5px' }}>
                <Button lefticon={<img src={twitterLogoState} width='23px' alt="image-here" />} backgroundColor={colors.transparent} borderColor={"gradient"} />
              </span>

              <span onClick={() => navigate("//www.pinterest.com/icreativesolofficial")} onMouseOver={() => changeHoverState('pintrest gradient')} onMouseLeave={() => changeHoverState('pintrest simple')} style={{ marginRight: '5px' }}>
                <Button lefticon={<img src={pintrestLogoState} width='23px' alt="image-here" />} backgroundColor={colors.transparent} borderColor={"gradient"} />
              </span>

            </Col>
            <Col lg={2} md={3} xs={2}></Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default DrawerNav;