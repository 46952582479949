import React, { memo } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Heading from "../../elements/Heading";
// import "./index.css";

const Colors = memo((props) => {
  const { title,content, textColor } = props;

  return (
    <Container>
      <Row className=" justify-content-center px-0">
        <Heading bold size="lg" heading={title} color={textColor} />
        {
          content?.map((items, index) => {
            return (
              <Col lg={3} md={6} className="px-0 py-3  d-flex flex-column align-items-center justify-content-center">

                <div style={{ width: '270px', height: '270px', borderRadius: '50%', backgroundColor: items.color }}>

                </div>
                <div className="text-center" >
                  <Heading bold size="xxs" heading={items.subtitle} color={textColor} />

                </div>
              </Col>
            )
          })
        }
      </Row>
    </Container>
  );
});

export default Colors;
