import "./index.css";
import React, { memo } from "react";
import { Spinner } from "react-bootstrap";


const Button = memo((props) => {

    const { title, lefticon,spinBorderTop, spinBorderColor,righticon, fontSize,fontWeight, backgroundColor, background, backgroundImage, textColor, disabled, gradientHover, simpleHover, width, borderColor, loading, onClick,borderRadius  } = props
    const generateClassName = () => {
        if (borderColor === "gradient") {
          return "border";
        } else if (borderColor === "transparent-border") {
          return "transparent-border";
        } else {
          return "";
        }
      };
      const className = `${generateClassName()} ${gradientHover ? "gradient-hover-effect" : " "} ${simpleHover ? "simple-hover-effect":" "} m-0 ${title ? "px-3" : ""} en-font-default`;

      return (
        <button
          onClick={onClick ? onClick : () => {}}
          disabled={loading || disabled}
          className={className}
          // className={`${borderColor==="gradient" && 'border' || borderColor==="transperent-border" && 'transperent-border'} ${gradientHover? 'gradient-hover-effect' : 'simple-hover-effect'} m-0 ${title && 'px-3'} en-font-default`}
          style={{
            // backgroundColor: background ? background : backgroundColor ? backgroundColor : "white",
            backgroundColor: backgroundColor ? backgroundColor : "white",
            backgroundImage: backgroundImage ? backgroundImage : "none",
            color: textColor ? textColor : "black",
            // height: 45,
            width: width ? width : "auto",
            borderRadius: borderRadius ? borderRadius : 6,
            border: borderColor ? "1px solid" : "none",
            borderColor: borderColor,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: fontSize ? fontSize : "15px",
            fontWeight: fontWeight ? fontWeight : "800",
            fontFamily: "Monument-Bold",
            paddingTop: "1rem",
            paddingBottom:"1rem"
          }}
        >
          <div className={`${title ? "px-3 d-flex align-items-center" : "px-1"}`}>
            <span className={`${gradientHover && "gradient-text"}`}>
              {lefticon}
              {title}
              {righticon}
            </span>
            {loading && (
              <Spinner className="ms-3 "  animation="border" variant="light" size="sm"  style={{ 
    borderColor: spinBorderColor,  // Light color for the border
    borderTopColor: spinBorderTop                 // Darker color for the top border to show rotation
  }} />
            )}
          </div>
        </button>
      );
});

export default Button;
