import React from 'react'
import  { memo } from "react";
import Heading from '../Heading';
import './style.css';
const Animation = memo((props) => {
    const {heading}=props
    return (
    <section className="animation">
    {heading.map((item, index) => (
      <div key={index}>
        <div>
        <Heading
          bold
          fontFamily={"bold-font"}
          fontSize={"75px"}
          size={"xxl"}
          heading={item.content}
          
        />
        {/* <h1 className='AnimationText'>{item.content}</h1> */}
        </div>
      </div>
    ))}
  </section>    
  )
})

export default Animation
