import "./style.css"
import React, { memo, } from "react";
import { Container, Col, Row } from "react-bootstrap";
import Heading from "../../elements/Heading";
import Button from "../../elements/Button";
import { useNavigate } from 'react-router-dom';
import { colors } from "../../../utils/colors";
import { useState } from "react";
const BrandingConsultance = memo((props) => {
    const navigate = useNavigate()
    const { title, content, button1, button2, EnableButton,subTitles,size} = props
    const [expanded, setExpanded] = useState(false);
    const [buttonText, setButtonText] = useState('Read More');
    const [height, setHeight] = useState('85px');
    const handleViewMore = () => {
        const newHeight = expanded ? '85px' : size;
        const newButtonText = expanded ? 'Read More' : 'Read Less';
        setHeight(newHeight);
        setButtonText(newButtonText);
        setExpanded(!expanded);
    }
    return (
        <Container className="px-lg-0 px-3 py-4 mt-2" fluid>
            <Container className="pb-3">
                <Row>
                    <Col md={12} className='d-flex'>
                    <h1 className="brandingConsultance">{title}</h1>
                    {/* <Heading   size="xxl" fontFamily={"bold-font"} fontSize={"36px"} heading={title} color={textColor} /> */}
                    </Col>
                    <Col lg={10} md={10} style={{ height: height, overflow: 'hidden',textAlign:'justify'}}>
                        <Heading className="subtitle" size="xxs" bold fontFamily={"reg-font"} heading={content} color={colors.gray} />
                        
                        {subTitles.map((item,index)=>{
                            return (
                                <Heading className="subtitle" size="xxs" fontFamily={"reg-font"} heading={item.subTitle} color={colors.gray} />
                            )
                              
                        })}
                                           </Col>
                {EnableButton && 
                    <Row className="mt-3">
                        <Col lg={5} md={5} sm={12} className="d-flex justify-content-between">
                          <Row>
                          <Col >
                            <Button onClick={handleViewMore}
                            title={buttonText}
                            backgroundColor={button1.backgroundColor}
                            textColor={button1.textColor}
                            borderColor={button1.borderColor}
                            // gradientHover={button1.gradientHover && true}
                            fontSize={'12px'}
                        />
                          </Col>

                          <Col className="ms-3">
                          <Button onClick={() => navigate(button2.link)}
                            title={button2.title}
                            textColor={button2.textColor}
                            backgroundImage={colors.gradient}
                            // gradientHover={button1.gradientHover && true}
                            fontSize={'12px'}
                        />
                          </Col>
                          </Row>   
                    </Col>
                </Row>
                    }
                </Row>
            </Container>
        </Container>
    )
})

export default BrandingConsultance;