export const colors = {
    black: "black",
    white: "white",
    red: "red",
    lightred: "rgba(177, 4, 33, 0.5)"  ,
    grey: "#cccccc",
    transparent:"transparent",
    green: "#12BC66",
    lightgreen: "#26D073",
    darkgreen: "#0C4A16",
    midgreen: "#50B79F",
    lightgrey : "#F9F9F9",
    midWhite: "#b3b3b3",
    darkblue: "#3894FF",
    seagreen: "#005E89",
    lightblue: "#F6FAFE",
    wheat:"#666666",
    gray:"#444444",
    gradient: "linear-gradient(to right, #D82AFB, #30BDE3)",
}