import React, { memo } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { colors } from "../../../utils/colors";
import Heading from "../../elements/Heading";
import "./index.css";
import StickyBox from "react-sticky-box";
import { Link } from "react-router-dom";

const Creativity = memo((props) => {
  const { title, subtitle, textColor, content } = props;

  return (
    <Container fluid style={{ backgroundColor: colors.black }}>

      <Row className="flex-start">
        <Col lg={6} md={6} className="px-lg-5 py-lg-5 mt-lg-5 pb-4 pb-md-0 pd-lg-0 py-md-5 py-5">
          <StickyBox offsetTop={125} offsetBottom={0}>
            <Row className="px-lg-4 px-md-4">
              <Col className="pt-1 px-lg-3 px-md-3">
                <Heading
                  bold
                  size="lg"
                  fontFamily={"bold-font"}
                  heading={title}
                  color={textColor}
                />
                <Heading
                  bold
                  fontFamily={"reg-font"}
                  size="xxs"
                  heading={subtitle}
                  color={textColor}
                />
              </Col>
            </Row>
          </StickyBox>
        </Col>

        <Col lg={6} md={6} className="pt-lg-5 py-md-5">
          {content?.map((items, index) => {
            return (
              <Row className="align-items-start">
                <Col lg={2} md={3} xs={2} className="pt-lg-5 py-lg-2 py-0">
                  <h2
                    className=""
                    style={{
                      color: "white",
                      fontWeight: "lighter",
                      fontSize: "60px",
                      color: "#A8A8A8",
                    }}
                  >
                    {items.values}
                  </h2>
                </Col>

                <Col
                  lg={8}
                  md={9}
                  xs={10}
                  className="pt-lg-5 d-flex align-items-center"
                >
                  <Row className="pt-lg-2 py-2 py-md-0 py-lg-0">
                   <Link className="text-decoration-none" to={items.link}>
                   <h3 className="text-gradient-hover-sec fontChange">
                      {items.title}
                    </h3>
                   </Link>
                    <p
                      style={{
                        color: colors.midWhite,
                        fontSize: "18px",
                        fontWeight: "400",
                      }}
                    >
                      {items.subtitle}
                    </p>
                  </Row>
                </Col>
              </Row>
            );
          })}
        </Col>
      </Row>
    </Container>
  );
});

export default Creativity;
