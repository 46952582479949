import React, { memo } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Heading from "../../elements/Heading";
import { colors } from "../../../utils/colors";

const ServicesOffer = memo((props) => {

    const { title, data, padContent, sizing, au } = props



    return (
        <Container className="" fluid style={{ backgroundColor: colors.black }}>
            <Container className="py-lg-5 p-0 py-md-5 py-0 pt-3">
                <Row className="m-0">
                    <Col md={padContent ? 8 : 12} className={au ? "px-lg-5 px-3" : "px-lg-0 px-3"}>
                        <Heading bolder fontFamily={au ? "reg-font" : "bold-font"} fontSize={"36px"} size={sizing ? "md" : "lg"} color={colors.white} heading={title} />
                    </Col>
                </Row>
                <Row className="mt-lg-4 mt-3">
                    <Col md={6} className="sectionBorderRight" style={{ borderRight: `solid ${colors.midWhite} 1px` }}>
                        <div className="px-lg-4 pt-lg-5 pt-3 px-3 sectionBorders" style={{ minHeight: "320px", paddingBottom: '30px', borderBottom: `solid ${colors.midWhite} 1px` }}>
                            <Heading bold fontFamily={"bold-font"} size="md" color={colors.white} heading={data.c1r1Heading} />
                            <Heading size="xxs" fontFamily={"reg-font"} color={colors.white} heading={data.c1r1Content} />
                        </div>

                        <div className="px-lg-4 pt-lg-5 px-3 pt-3" style={{ minHeight: "220px" }}>
                            <Heading bold fontFamily={"bold-font"} size="md" color={colors.white} heading={data.c1r2Heading} />
                            <Heading size="xxs" fontFamily={"reg-font"} color={colors.white} heading={data.c1r2Content} />
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="px-lg-4 pt-lg-5 px-3 pt-3 sectionBorders" style={{ minHeight: "320px", paddingBottom: '30px', borderBottom: `solid ${colors.midWhite} 1px` }}>
                            <Heading bold fontFamily={"bold-font"} size="md" color={colors.white} heading={data.c2r1Heading} />
                            <Heading size="xxs" fontFamily={"reg-font"} color={colors.white} heading={data.c2r1Content} />
                        </div>

                        <div className="px-lg-4 pt-lg-5 px-3 pt-3" style={{ minHeight: "220px" }}>
                            <Heading bold fontFamily={"bold-font"} size="md" color={colors.white} heading={data.c2r2Heading} />
                            <Heading size="xxs" fontFamily={"reg-font"} color={colors.white} heading={data.c2r2Content} />
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>
    )

})

export default ServicesOffer;