import React from 'react';
import { useNavigate } from 'react-router-dom'; 
import Button from '../../elements/Button';
import { colors } from '../../../utils/colors';

const SocialMediaButton = ({ link, icon, hoverState, onMouseOver, onMouseLeave }) => {
  const navigate = useNavigate(); 

  return (
    <span style={{ marginRight: '5px' }}
      onClick={() => navigate(link)}
      onMouseOver={() => onMouseOver(hoverState)}
      onMouseLeave={() => onMouseLeave(`${hoverState.split(" ")[0]} simple`)}
    >
      <Button
        lefticon={<img src={icon} width="23px" height='30px' alt="image-here" />}
        backgroundColor={colors.transparent}
        borderColor={'transparent-border'}
      />
    </span>
  );
};

export default SocialMediaButton;
