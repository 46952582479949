import gql from "graphql-tag";

export const HOME_PAGE_BLOGS = (tagSlug) => {
  var x = tagSlug?.length > 0 ? JSON.stringify(tagSlug) : null
  
 
  return gql`
query blogPost {
  posts(
    first: 3
    where: {status: PUBLISH, orderby: {field: DATE, order: DESC}, tagSlugIn:${x}}

  ) {
    edges {
      node {
        id
        date
        title
        uri
        slug
        postId
        link
        featuredImage {
          node {
            sourceUrl
            slug
            seo {
              breadcrumbs {
                text
                url
              }
              canonical
              cornerstone
              focuskw
              fullHead
              metaDesc
              metaKeywords
              metaRobotsNofollow
              metaRobotsNoindex
              opengraphAuthor
              opengraphDescription
              opengraphModifiedTime
              opengraphPublishedTime
              opengraphPublisher
              opengraphSiteName
              opengraphTitle
              opengraphType
              opengraphUrl
              readingTime
              title
              twitterDescription
              twitterTitle
              schema {
                articleType
                pageType
              }
            }
          }
        }
        tags {
          edges {
            node {
              name
            }
          }
        }
        seo {
          breadcrumbs {
            text
            url
          }
          canonical
          metaDesc
          metaKeywords
          title
          schema {
            articleType
            pageType
          }
          twitterTitle
          twitterDescription
          fullHead
          cornerstone
          focuskw
          metaRobotsNofollow
          metaRobotsNoindex
          opengraphAuthor
          opengraphDescription
          opengraphModifiedTime
          opengraphPublishedTime
          opengraphPublisher
          opengraphSiteName
          opengraphTitle
          opengraphType
          opengraphUrl
          readingTime
        }
      }
    }
  }
}
  `
}

export const GET_ALL_BLOGS = (endCursor) => {
  return gql`
 query allblog {
  posts(where: {status: PUBLISH}, after: "${endCursor}", first: 3) {
    edges {
      node {
        id
        date
        title
        uri
        slug
        postId
        link
        featuredImage {
          node {
            sourceUrl
            slug
            seo {
              breadcrumbs {
                text
                url
              }
              canonical
              fullHead
              metaDesc
              title
              schema {
                articleType
                pageType
              }
              metaKeywords
              twitterDescription
              twitterTitle
            }
          }
        }
        tags {
          edges {
            node {
              name
            }
          }
        }
        seo {
          breadcrumbs {
            text
            url
          }
          canonical
          fullHead
          metaDesc
          metaKeywords
          schema {
            articleType
            pageType
          }
          title
          twitterDescription
          twitterTitle
        }
      }
    }
    pageInfo {
      endCursor
      hasPreviousPage
      startCursor
      hasNextPage
    }
  }
}
 `
}

export const GET_SINGLE_BLOG = (uri, format) => {
  
  return gql`
query singleBlog {
  post(id: "${uri}", idType: URI) {
    author {
      node {
        name
      }
    }
    comments {
      nodes {
        author {
          node {
            name
          }
        }
        content(format: RENDERED)
      }
    }
    commentCount
    content(format: RENDERED)
    date
    featuredImage {
      node {
        altText
        sourceUrl
        seo {
          breadcrumbs {
            text
            url
          }
          canonical
          fullHead
          metaDesc
          title
          schema {
            articleType
            pageType
          }
          metaKeywords
          twitterDescription
          twitterTitle
          cornerstone
          focuskw
          metaRobotsNofollow
          metaRobotsNoindex
          opengraphAuthor
          opengraphDescription
          opengraphModifiedTime
          opengraphPublishedTime
          opengraphPublisher
          opengraphSiteName
          opengraphTitle
          opengraphType
          opengraphUrl
          readingTime
        }
      }
    }
    slug
    tags {
      nodes {
        name
      }
    }
    title(format: RENDERED)
    uri
    seo {
      breadcrumbs {
        text
        url
      }
      canonical
      fullHead
      metaDesc
      metaKeywords
      schema {
        articleType
        pageType
      }
      title
      twitterDescription
      twitterTitle
      cornerstone
      focuskw
      metaRobotsNofollow
      metaRobotsNoindex
      opengraphAuthor
      opengraphDescription
      opengraphModifiedTime
      opengraphPublishedTime
      opengraphPublisher
      opengraphSiteName
      opengraphTitle
      opengraphType
      opengraphUrl
      readingTime
    }
  }
}
  `
}

