
export const routes = {
HOME_AE: "/ae-en",
HOME_US: "/us-en",

 BRAND_DEVELOPMENT_US: "/us-en/brand-development",
BRAND_AE: "/ae-en/brand-and-strategy",
DIGITAL_AE: "/ae-en/digital-solutions",
ADVERTISING_AE: "/ae-en/advertising-agency",
MARKETIG_AE: "/ae-en/marketing-services",

BRAND_US: "/us-en/brand-and-strategy",
ADVERTISING_US: "/us-en/advertising-agency",
MARKETIG_US: "/us-en/marketing-services",
// BRAND_US: "/us-en/brand-and-strategy",
DIGITAL_US: "/us-en/digital-solutions",


BRANDING_STRATEGY_AE: "/ae-en/branding-strategy",
BRANDING_MARKETING_AE: "/ae-en/brand-marketing",
BRANDING_MESSAGING_AE : "/ae-en/brand-messaging",
BRANDING_IDENTITY_AE : "/ae-en/brand-identity-agency",
BRANDING_POSTIONING_AE: "/ae-en/brand-positioning",



BRANDING_STRATEGY_US: "/us-en/branding-strategy",
BRANDING_MARKETING_US: "/us-en/brand-marketing",
BRANDING_MESSAGING_US : "/us-en/brand-messaging",
BRANDING_IDENTITY_US : "/us-en/brand-identity-agency",
BRANDING_POSTIONING_US: "/us-en/brand-positioning",


INBOUND_MARKETING_AE : "/ae-en/inbound-marketing-services",
CONTENT_MARKETING_AE : "/ae-en/content-marketing-services",
PERFORMANCE_MARKETING_AE : "/ae-en/performance-marketing",
COPY_WRITING_MARKETING_AE : "/ae-en/copywriting-services",
DIGITAL_MARKETING_AE: "/ae-en/digital-marketing-services",

INBOUND_MARKETING_US : "/us-en/inbound-marketing-services",
CONTENT_MARKETING_US : "/us-en/content-marketing-services",
PERFORMANCE_MARKETING_US : "/us-en/performance-marketing",
COPY_WRITING_MARKETING_US : "/us-en/copywriting-services",
DIGITAL_MARKETING_US: "/us-en/digital-marketing-services",


UI_UX_DESIGN_US:"/us-en/ui-ux-design-services",
VIDEO_PRODUCTION_US:"/us-en/video-production-company",
WEB_DESIGN_US: "/us-en/web-design-and-development",
APP_DEVELOPMENT_US: "/us-en/mobile-app-development",
ILLUSTRATION_DESIGN_US: "/us-en/digital-illustration", 


UI_UX_DESIGN_AE:"/ae-en/ui-ux-design-services",
VIDEO_PRODUCTION_AE:"/ae-en/video-production-company",
WEB_DESIGN_AE: "/ae-en/web-design-and-development",
ILLUSTRATION_DESIGN_AE: "/ae-en/digital-illustration",
APP_DEVELOPMENT_AE: "/ae-en/mobile-app-development",



BRANDING_OOH_ADVERTISING_AE:"/ae-en/ooh-advertising",
INFLUENCER_MARKETING_AE:"/ae-en/influencer-marketing-strategy",
PRINT_ADVERTISING_AE:"/ae-en/print-advertising-agency",
PR_MEDIA_AE:"/ae-en/pr-services",
MEDIA_BUYING_AE:"/ae-en/media-buying-agency",


BRANDING_OOH_ADVERTISING_US:"/us-en/ooh-advertising",
INFLUENCER_MARKETING_US:"/us-en/influencer-marketing-strategy",
PRINT_ADVERTISING_US:"/us-en/print-advertising-agency", 
PR_MEDIA_US:"/us-en/pr-services",
MEDIA_BUYING_US:"/us-en/media-buying-agency",

AREA_OF_EXPERTISE_AE: "/ae-en/expertise",
CONTACT_US_AE: "/ae-en/contact-us",
ABOUT_US_AE: "/ae-en/about-us",
OUR_BLOGS_AE: "/ae-en/creative-blogs",


AREA_OF_EXPERTISE_US: "/us-en/expertise",
CONTACT_US: "/us-en/contact-us",
ABOUT_US: "/us-en/about-us",
OUR_BLOGS_US: "/us-en/creative-blogs",



PORTFOLIO_US: "/us-en/portfolio",
PORTFOLIO_AE: "/ae-en/portfolio",



LOGO_DESIGN_AE:"/ae-en/creative-logo-design",
CREATIVE_LOGO_DESIGNS_AE: "/ae-en/creative-logo-design",
MARKETING_COLLATERAL_AE: "/ae-en/marketing-collateral",


LOGO_DESIGN_US:"/us-en/creative-logo-design",
CREATIVE_LOGO_DESIGNS_US: "/us-en/creative-logo-design",
MARKETING_COLLATERAL_US: "/us-en/marketing-collateral",


CREATIVE_WRITING_US: "/us-en/creative-writing",
CREATIVE_WRITING_AE: "/ae-en/creative-writing",

BACKEND_DEVELOPMENT_SERVICES_US: "/us-en/backend-development",
BACKEND_DEVELOPMENT_SERVICES_AE: "/ae-en/backend-development",

SEO_SERVICES_US: "/us-en/seo-services",
SEO_SERVICES_AE: "/ae-en/seo-services",

MOTION_GRAPHICS_AE: "/ae-en/motion-graphics",
SOCIAL_MEDIA_MARKETING_SERVICES_AE: "/ae-en/social-media-marketing-agency",

MOTION_GRAPHICS_US: "/us-en/motion-graphics",
SOCIAL_MEDIA_MARKETING_SERVICES_US: "/us-en/social-media-marketing-agency",









// BLOG1: "/nike-custom-logo-design/",
// BLOG2: "/why-you-should-use-creative-services-for-your-business",
// BLOG3: "/5-kickass-strategies-for-successful-brand-development",
// BLOG4: "/viral-marketing-tips-for-a-successful-social-media-campaign",



ACT_OF_KINDNESS_AE: "/ae-en/portfolio/joinaok",
ABSOLUTE_ID_AE: "/ae-en/portfolio/absolute-id",
CHILD_OF_GREEN_EARTH_AE: "/ae-en/portfolio/children-of-the-earth",
LAWN_CASE_STUDY_AE: "/ae-en/portfolio/lgp-lawn",
BOOK_SYMPHONY_AE: "/ae-en/portfolio/book-symphony",
BOOK_A_CONSULTANT_AE: "/ae-en/book-a-consultation",
REV_ME_AE: "/ae-en/portfolio/revme",
HAIR_AT_HOME_AE: "/ae-en/portfolio/hair-at-home/",
MOBLEYS_GAMING_AE: "/ae-en/portfolio/mobleysgamingrvg/",
THE_LIFE_U_DESERVE_DAY_AE: "/ae-en/portfolio/thelifeudeserve2day/",


ACT_OF_KINDNESS_US: "/us-en/portfolio/joinaok",
ABSOLUTE_ID_US: "/us-en/portfolio/absolute-id",
CHILD_OF_GREEN_EARTH_US: "/us-en/portfolio/children-of-the-earth",
LAWN_CASE_STUDY_US: "/us-en/portfolio/lgp-lawn",
BOOK_SYMPHONY_US: "/us-en/portfolio/book-symphony",
BOOK_A_CONSULTANT_US: "/us-en/book-a-consultation",
REV_ME_US: "/us-en/portfolio/revme",
HAIR_AT_HOME_US: "/us-en/portfolio/hair-at-home/",
MOBLEYS_GAMING_US: "/us-en/portfolio/mobleysgamingrvg/",
THE_LIFE_U_DESERVE_DAY_US: "/us-en/portfolio/thelifeudeserve2day/",
THANK_YOU_AE:"/ae-en/thank-you",
THANK_YOU_US:"/us-en/thank-you",



BLOG_PAGE_AE: "/blogs/:ids",
BLOG_PAGE_US: "/blogs/:ids"


}
