import './index.css';
import React, { memo,useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Heading from "../../elements/Heading";
import Button from "../../elements/Button";
import TopHeader from "../TopHeader";
import { colors } from "../../../utils/colors";
import { useNavigate } from 'react-router-dom';

const Header = memo((props) => {
  useEffect(()=>{
    window.scrollTo(0,0);
},[])
  const navigate = useNavigate()
  
  const {
    Col1,
    title,
    sizing,
    subtitle,
    button,
    simple,
    textColor,
    backgroundColor,
    padContent,
    coloumns,
    logo,
    toggle,
    padCol,
    shortText,
    portfolio,
    page,
    ptop,
  } = props;

  return (
    <Container className='' fluid style={{ backgroundColor: backgroundColor }}>
        {
            logo &&
            <Row className="mb-5 width-100  py-2"
            style={{ position: "fixed",zIndex : '1000', width:'100%',backgroundColor:'black'}}
            >
              <Col className='px-lg-4 px-md-4 py-2 mx-lg-5 px-md-4 mx-md-4'>
                <TopHeader image={logo} toggle={toggle} alt="image-here"/>
              </Col>
            </Row>
        }
        
      <Container className={padContent ? "py-5 py-lg-0 px-0 py-md-0" : "pb-2 pb-lg-5 pt-lg-5 pt-5 px-0"}>
        {
          shortText &&
          <Row className="m-xs-0">
            <Col className="px-0">
              <Heading size="xxs" heading={shortText} color={textColor} />
            </Col>
          </Row>
        }
        <Row className={page ? "pt-0 px-0 pt-md-0 m-xs-0" : ptop ?  'pt-5 m-0'  : "m-xs-0 pt-lg-5 px-lg-3 px-md-1 pt-md-5 pt-0 pb-4 px-2"}>
          <Col lg={padCol ? 11 : Col1 ? 9 : 8} className={ portfolio ? "py-0 pt-0 px-lg-0 px-md-3" : ptop ? 'pt-5 py-5' : "px-lg-0 px-md-3 px-2 py-0 pt-5 pt-lg-0 pt-md-1"}>
            <Heading bold fontFamily={"bold-font"}  fontSize={"36px"} size={sizing ? "xxl" : "lg"} heading={title} color={textColor} />
          </Col>
        </Row>

      {
          subtitle &&
          // <Row className={button ? "py-4 px-3 px-lg-3 m-xs-0 px-md-2" : "pt-0 px-3 px-lg-3 px-md-0 py-md-0 m-xs-0"}>
          <Col md={coloumns ? 8 : Col1 ? 9 : 10} className="px-2 px-lg-0 ">
            <Heading size="xxs" fontFamily={"reg-font"} heading={subtitle} color={colors.grey} />
          </Col>
        // </Row>

      }
        
        {button && (
          // <Row className="">
            <Col className="px-lg-0 px-2 px-md-0">
              <Button onClick={()=>navigate(button.link)}
                title={button.title}
                backgroundColor={backgroundColor}
                textColor={textColor}
                borderColor={simple ? button.borderColor : "gradient"}
                gradientHover={button.gradientHover && true}
              />
            </Col>
          // </Row>
        )}
      </Container>
    </Container>
  );
});

export default Header;
