import { createSlice } from '@reduxjs/toolkit'
import { getRegion } from '../utils/util';
const initialState = {
  region: getRegion(),
  slug:[]
}
export const RegionSlice = createSlice({
  name: 'Region',
  initialState,
  reducers: {
    AddingRegion: (state, action) => {
      state.region = action.payload;
    },
    AddingSlug: (state, action) => {
      state.slug = action.payload;
    },
  },
})

export const { AddingRegion, AddingSlug } = RegionSlice.actions

export default RegionSlice.reducer