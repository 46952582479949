import React, { memo } from "react";
import Accordion from 'react-bootstrap/Accordion';
import Heading from "../Heading";
import "./index.css"

const AccordionComp = memo((props) => {

    const { items,  textcolor } = props


    return (
        <Accordion className="py-0" flush>
            {
                items.map((item, index) => {
                    return (
                        <Accordion.Item eventKey={index}>
                            <Accordion.Header className="py-2">
                            {/* <Heading index={`0${index+1}`} fontFamily={"reg-font"} color={textcolor} nomargin size="xxs"  />   */}
                              <Heading index={`0${index+1}`} fontFamily={"reg-font"} color={textcolor} nomargin size="xxs" heading={item.title} />
                            </Accordion.Header>                            {
                                item.content.map((items, index) => {
                                
                                    return (
                                        <Accordion.Body >
                                            {
                                                items.simpleText &&  items.simpleText
                                            }
                                            {
                                                <div className="my-3">{items.bulletText?.map((value,index)=>{
                                                    return(
                                                        <ul className="px-3">
                                                        <li>
                                                            
                                                            {value}
                                                        </li>
                                                       </ul>
                                                    )
                                                })}</div>
                                               
                                            }
                                        </Accordion.Body>
                                    )
                                })
                            }

                        </Accordion.Item>
                    )
                })
            }

        </Accordion>
    )
})

export default AccordionComp;