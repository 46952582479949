import React, { memo } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { colors } from "../../../utils/colors";
import Accordion from "../../elements/Accordion";
import Heading from "../../elements/Heading";

const FAQs = memo((props) => {
  const { accordionsArray, heading } = props;

  return (
    <Container fluid style={{ backgroundColor: colors.black }}>
      <Container className=" pt-lg-5 pt-4 ">
        <Row className="d-flex flex-column">
          <Col className="px-lg-0 px-3">
            <Heading
              bolder
              fontFamily={"bold-font"}
              size="lg"
              color={colors.white}
              heading={heading}
            />
          </Col>
      
          <Col md={8} className="px-lg-0 px-3">
            <Accordion
              theme={colors.black}
              textcolor={colors.white}
              items={accordionsArray}
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
});

export default FAQs;
