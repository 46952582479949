// import { configureStore } from '@reduxjs/toolkit'
// import RegionSlice from './RegionSlice'
// import { persistStore, persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage'; 


// export const store = configureStore({
//   reducer: {
//     region: RegionSlice,  
// },
 
// })

import { configureStore } from '@reduxjs/toolkit';
import RegionSlice from './RegionSlice';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'root', 
  storage, 
};

const persistedReducer = persistReducer(persistConfig, RegionSlice);

export const store = configureStore({
  reducer: {
    region: persistedReducer, 
  },
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware({
  //     serializableCheck: false, // Disable serializability check for non-serializable data
  //   }),
});

export const persistor = persistStore(store);
