
import React, { memo, useState } from "react";
import CarouselModule from "../CarouselModule";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
// import CarouselModule from "../../components/modules/CarouselModule";
import { colors } from "../../../utils/colors";
import Heading from "../../elements/Heading";




const CarousalComponent = memo((props)=>{

    const {textColor,title,  cards,backgroundColor,newCarousal}=props

    const navigate = useNavigate()
    return(
        <Container className="px-lg-0 px-3 pb-5 pt-2" fluid style={{backgroundColor : backgroundColor}}>

                <Row className="m-0">
                    {
                        title &&
                         <Col className="px-0 d-flex justify-content-center text-center py-lg-4">
                            <Heading bold size="lg" fontFamily={"bold-font"} fontSize={"36px"} heading={title} color={textColor} />
                         </Col>
                    }

                    <Col className="">
                    <CarouselModule
                        newCarousal={newCarousal}
                        cards={cards}
                        />
                    </Col>
                </Row>

        </Container>
    )
})

export default CarousalComponent;