import "./style.css";
import React, { memo, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Heading from "../../elements/Heading";
import Button from "../../elements/Button";
import TopHeader from "../TopHeader";
import { colors } from "../../../utils/colors";
import { useNavigate } from 'react-router-dom';
import { content } from "../../../content";
import Animation from "../../elements/Animation";
import AnimationText from "../../elements/TextAnimation";
const BrandHeader = memo((props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  const navigate = useNavigate()
  // const { WeDoHeading1, WeDoHeading2, WeDoHeading3 } = content
  const {
    Col1,
    title,
    subtitle,
    button,
    simple,
    textColor,
    backgroundColor,
    coloumns,
    content
  } = props;

  return (
    <Container className="px-lg-0 " fluid style={{ backgroundColor: backgroundColor }}>
      <Container className="pt-lg-5 pt-3 ">
        <Row >
          <Col className="py-2">
            <Heading bold fontFamily={"bold-font"} fontSize={"100px"} size={"xxl"} heading={title} color={textColor} />
          </Col>
        </Row>
          <Row >
            <Col >
            < Animation heading={content}/>
           {/* <AnimationText heading={content}/> */}
            </Col>
          </Row>
        
        {
          subtitle &&

          <Col md={coloumns ? 8 : Col1 ? 9 : 10} className="px-1 px-lg-0 py-1">
            <Heading className="subtitle" size="xxs" fontFamily={"reg-font"} heading={subtitle} color={colors.grey} />
          </Col>

        }
        {button && (
          <Col className="px-lg-0 px-2 px-md-0 py-4 pb-5">
            <Button onClick={() => navigate(button.link)}
              title={button.title}
              backgroundColor={backgroundColor}
              textColor={textColor}
              borderColor={simple ? button.borderColor : "gradient"}
              gradientHover={button.gradientHover && true}
            />
          </Col>
        )}
      </Container>
    </Container>
  );
});

export default BrandHeader;
