import React, { memo, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Heading from "../../elements/Heading";
import Button from "../../elements/Button";
import { colors } from "../../../utils/colors";
import { useNavigate } from "react-router-dom";
import { content as cont } from "../../../content";
import CountUp from 'react-countup';
import ScrollTrigger from "react-scroll-trigger";
import { useSelector } from 'react-redux';
import "./index.css"

const InfoCard = memo((props) => {

    const { title, subtitle, button, symbol, email,link, textColor, addOn, content,onButtonClick,AppDevelopment } = props
    const { Email } = cont;
    const navigate = useNavigate()
    const [counterOn, setCounterOn] = useState(false)
    const region = useSelector((state) => state.region.region);


    return (
        <Container fluid className="main-container p-0">
            <Container className="py-lg-5 py-4 py-lg-3 py-md-0 pt-md-4 pb-md-3 text-center">
                <Row className="m-0 d-flex flex-column text-center">
                    <Col>
                        <Heading bold fontFamily={"bold-font"} size="lg" heading={title} color={textColor} />
                  
                        <Heading fontFamily={"reg-font"} size="xxs" heading={subtitle} color={textColor} />
                    </Col>


                    {addOn &&
                        <Row className="m-0">

                            {
                                content.map((items, index) => {
                                    return (
                                        <Col md={4}  className="py-lg-5 py-1">
                                            <span className=" d-flex justify-content-center align-items-center countFont" 
                                            style={{ fontSize: '56px', fontFamily: 'Monument-Bold', color: 'white' }}>{items.symbol}
                                                <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                                                    {counterOn && <CountUp className="counter" start={0} end={items.subtitle} duration={1.5} 
                                                    style={{ fontSize: '56px', fontFamily: 'Monument-Bold', color: 'white' }} />}
                                                    {items.secSymbol}
                                                </ScrollTrigger>
                                            </span>
                                            <Heading size="xxs" fontFamily={"rob-font"} heading={items.title} color={textColor} />
                                        </Col>
                                    )
                                })

                            }
                        </Row>
                    }
                    <Col className="d-flex flex-column align-items-center py-2">
                        <Button className="infoButtonText" 
                        // onClick={!AppDevelopment ? navigate( region == "ae"?"/ae-en/book-a-consultation":"/us-en/book-a-consultation") : onModalOpen()} 
                        onClick={() => (!AppDevelopment ? navigate(link) : onButtonClick())}
                        title={button?.title} backgroundColor={colors.transparent} textColor={textColor} borderColor={textColor} simpleHover={button.simpleHover}/>
                        <a href={"mailto:" + Email} className="pt-2 text-white text-decoration-none font-bold" size="xxs" color={textColor}>{Email}</a>
                    </Col>
                </Row>
            </Container>
        </Container>
    )

})

export default InfoCard;